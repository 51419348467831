import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlight from "../components/ProductHighlight";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";

const Content = () => {
  return (
    <Layout>
      <Seo title="Get help with your Dr Pepper addiction" description="Get help with your Dr Pepper addiction" />
      <Container>
        <Row>
          <Col>
            <h1 className="mb-3">Get Help?</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <p className="mb-3">Help is here! You can order your next Dr Pepper fix straight from this site. Click any of the links below to order your choice of Dr Pepper.</p>
          </Col>
        </Row>

        <ProductHighlight />

        <Row>
          <Col>
            <ProductLinkListHorizonal title="Products" />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
